//- Template Variable
//- ----------------------------------------------

$template: 'esports';


//- Base
//- ----------------------------------------------

$font-family-base: 'Open Sans', sans-serif !default;
$font-family-accent: 'Roboto Condensed', sans-serif !default;
$base-font-size: 14px !default;
$base-line-height: 26px !default;
$font-size-mobile: 13px !default;
$line-height-mobile: 22px !default;
$body-font-weight: 400 !default;

$font-size-sm:         ($base-font-size * .857) !default;


//- Colors
//- ----------------------------------------------

$color-primary:        #00ff5b !default;
$color-primary-darken: #1bd75e !default;
$color-dark:           #362b45 !default;
$color-dark-2:         #403351 !default;
$color-dark-lighten:   #392d49 !default;
$color-dark-lighten-2: #4b3b60 !default;
$color-gray:           #a59cae !default;
$color-gray-2:         #8c8297 !default;
$color-gray-3:         #7a7283 !default;
$color-2:              #6a3bc0 !default;
$color-2-lighten:      #8040f5 !default;
$color-3:              #f92552 !default;
$color-4:              #ffb400;
$color-4-darken:       $color-4;

$color-white:          #fff !default;

$color-success:        #00ff5b !default;
$color-info:           #00deff !default;
$color-warning:        #ffb400 !default;
$color-danger:         #f92552 !default;

// Duotone
$duotone-layer-dark: #1d1429 !default;
$duotone-base-color-1: $color-dark !default;
$duotone-base-color-2: darken(#53436a, 20%) !default;

$body-bg-color: #2b2236 !default;
$body-font-color: $color-gray !default;
$body-font-color-inverse: $color-gray !default;

// Links
$link-color:            $color-primary !default;
$link-decoration:       none !default;
$link-color-hover:      $color-primary-darken !default;
$link-decoration-hover: none !default;

// Social
$social-facebook:      #3B5998 !default;
$social-twitter:       #1DA1F2 !default;
$social-google:        #DC4E41 !default;
$social-instagram:     #E4405F !default;
$social-twitch:        #6441A4 !default;
$social-youtube:       #FF0000 !default;
$social-linkedin:      #0077B5 !default;
$social-vk:            #6383A8 !default;
$social-odnoklassniki: #F4731C !default;
$social-pinterest:     #BD081C !default;
$social-rss:           #ffcc00 !default;
$social-calendar:      #0e95f3 !default;

$social-links: ();
$social-links: map-merge((
	"facebook":      $social-facebook,
	"fb":            $social-facebook, // alias
	"twitter":       $social-twitter,
	"instagram":     $social-instagram,
	"google":        $social-google,
	"twitch":        $social-twitch,
	"youtube":       $social-youtube,
	"linkedin":      $social-linkedin,
	"vk":            $social-vk,
	"odnoklassniki": $social-odnoklassniki,
	"pinterest":     $social-pinterest,
	"rss":           $social-rss
), $social-links);
// stylelint-enable


//- Components
//- ----------------------------------------------

$padding-base-vertical:     12px !default;
$padding-base-horizontal:   20px !default;



//- Tables
//- ----------------------------------------------

$table-cell-padding:            13px !default;
$table-cell-padding-lg:         17px !default;
$table-condensed-cell-padding:  5px !default;
$table-bg:                      transparent;
$table-bg-accent:               #f9f9f9 !default;
$table-bg-hover:                $color-dark-2 !default;
$table-bg-active:               $table-bg-hover !default;
$table-font-color:              $body-font-color !default;
$table-font-weight:             400 !default;

$table-border-color:            $color-dark-lighten-2 !default;
$table-thead-color:             #fff !default;
$table-thead-bg-color:          $color-dark-2 !default;
$table-thead-font-style:        italic !default;
$table-highlight:               #fff !default;

$table-striped-bg:              rgba(0,0,0,.05) !default;


//- Buttons
//- ----------------------------------------------

$btn-font-weight:                700 !default;
$btn-font-style:                 italic !default;
$btn-font-family:                $font-family-accent !default;

// Text Transform
$btn-text-transform:             uppercase !default;
$btn-text-transform-lg:          uppercase !default;
$btn-text-transform-sm:          uppercase !default;
$btn-text-transform-xs:          uppercase !default;

// Line-Height
$btn-line-height:                1.5em !default;
$btn-line-height-lg:             1.45em !default;
$btn-line-height-sm:             1.5em !default;
$btn-line-height-xs:             1.5em !default;

// Paddings
$btn-padding-vertical:           12px !default;
$btn-padding-horizontal:         42px !default;
$btn-padding-vertical-lg:        13px !default;
$btn-padding-horizontal-lg:      80px !default;
$btn-padding-vertical-sm:        8px !default;
$btn-padding-horizontal-sm:      38px !default;
$btn-padding-vertical-xs:        5px !default;
$btn-padding-horizontal-xs:      13px !default;

// Border Radius
$btn-border-radius:              22px !default;
$btn-border-radius-lg:           24px !default;
$btn-border-radius-sm:           16px !default;
$btn-border-radius-xs:           12px !default;

// Font Size
$btn-border-font-size:           12px !default;
$btn-border-font-size-lg:        14px !default;
$btn-border-font-size-sm:        10px !default;
$btn-border-font-size-xs:        9px !default;

// Button Default
$btn-default-color:              #fff !default;
$btn-default-color-hover:        #fff !default;
$btn-default-bg:                 $color-gray-2 !default;
$btn-default-hover:              $color-primary-darken !default;
$btn-default-border:             transparent !default;
$btn-default-border-hover:       transparent !default;

// Button Default - Alternative
$btn-default-alt-color:          #fff !default;
$btn-default-alt-color-hover:    #fff !default;
$btn-default-alt-bg:             $color-dark-lighten-2 !default;
$btn-default-alt-hover:          $color-2 !default;
$btn-default-alt-border:         transparent !default;
$btn-default-alt-border-hover:   transparent !default;

// Button Primary
$btn-primary-color:              #fff !default;
$btn-primary-color-hover:        #fff !default;
$btn-primary-bg:                 $color-2 !default;
$btn-primary-hover:              $color-2-lighten !default;
$btn-primary-border:             transparent !default;
$btn-primary-border-hover:       transparent !default;

// Button Primary Inverse
$btn-primary-inverse-color:        #fff !default;
$btn-primary-inverse-color-hover:  #fff !default;
$btn-primary-inverse-bg:           $color-primary-darken !default;
$btn-primary-inverse-hover:        $color-primary !default;
$btn-primary-inverse-border:       transparent !default;
$btn-primary-inverse-border-hover: transparent !default;

// Button Success
$btn-success-color:              #fff !default;
$btn-success-color-hover:        #fff !default;
$btn-success-bg:                 $color-success !default;
$btn-success-hover:              darken($color-success, 3%) !default;
$btn-success-border:             transparent !default;
$btn-success-border-hover:       transparent !default;

// Button Info
$btn-info-color:                 #fff !default;
$btn-info-color-hover:           #fff !default;
$btn-info-bg:                    $color-info !default;
$btn-info-hover:                 darken($color-info, 3%) !default;
$btn-info-border:                transparent !default;
$btn-info-border-hover:          transparent !default;

// Button Warning
$btn-warning-color:              #fff !default;
$btn-warning-color-hover:        #fff !default;
$btn-warning-bg:                 $color-warning !default;
$btn-warning-hover:              darken($color-warning, 3%) !default;
$btn-warning-border:             transparent !default;
$btn-warning-border-hover:       transparent !default;

// Button Danger
$btn-danger-color:               #fff !default;
$btn-danger-color-hover:         #fff !default;
$btn-danger-bg:                  $color-danger !default;
$btn-danger-hover:               darken($color-danger, 3%) !default;
$btn-danger-border:              transparent !default;
$btn-danger-border-hover:        transparent !default;

// Buttons Default Outline
$btn-o-default-color:            #fff !default;
$btn-o-default-color-hover:      #fff !default;
$btn-o-default-border:           $color-gray-3 !default;
$btn-o-default-border-hover:     $color-4 !default;
$btn-o-default-bg-hover:         transparent !default;

// Button Primary - Outline
$btn-o-primary-color:            #fff !default;
$btn-o-primary-color-hover:      #fff !default;
$btn-o-primary-border:           $color-gray-3 !default;
$btn-o-primary-border-hover:     $btn-primary-hover !default;
$btn-o-primary-bg-hover:         transparent !default;

// Button Primary Inverse - Outline
$btn-o-inverse-color:            #fff !default;
$btn-o-inverse-color-hover:      #fff !default;
$btn-o-inverse-border:           #fff !default;
$btn-o-inverse-border-hover:     $btn-primary-hover !default;
$btn-o-inverse-bg-hover:         $btn-primary-hover !default;

// Button Success - Outline
$btn-o-success-color:            #fff !default;
$btn-o-success-color-hover:      #fff !default;
$btn-o-success-border:           $btn-success-bg !default;
$btn-o-success-border-hover:     $btn-success-hover !default;
$btn-o-success-bg-hover:         $btn-success-hover !default;

// Button Info - Outline
$btn-o-info-color:               #fff !default;
$btn-o-info-color-hover:         #fff !default;
$btn-o-info-border:              $btn-info-bg !default;
$btn-o-info-border-hover:        $btn-info-hover !default;
$btn-o-info-bg-hover:            $btn-info-hover !default;

// Button Warning - Outline
$btn-o-warning-color:            #fff !default;
$btn-o-warning-color-hover:      #fff !default;
$btn-o-warning-border:           $btn-warning-bg !default;
$btn-o-warning-border-hover:     $btn-warning-hover !default;
$btn-o-warning-bg-hover:         $btn-warning-hover !default;

// Button Danger - Outline
$btn-o-danger-color:             #fff !default;
$btn-o-danger-color-hover:       #fff !default;
$btn-o-danger-border:            $btn-danger-bg !default;
$btn-o-danger-border-hover:      $btn-danger-hover !default;
$btn-o-danger-bg-hover:          $btn-danger-hover !default;


//- Typography
//- ----------------------------------------------

$font-size-h1: 42px !default;
$font-size-h2: 32px !default;
$font-size-h3: 30px !default;
$font-size-h4: 26px !default;
$font-size-h5: 16px !default;
$font-size-h6: 12px !default;

$headings-font-family:    $font-family-accent !default;
$headings-color:          #fff !default;
$headings-color-inverse:  $color-dark !default;
$headings-line-height:    1.2 !default;
$headings-text-transform: uppercase !default;

$font-style-h1: italic !default;
$font-style-h2: italic !default;
$font-style-h3: italic !default;
$font-style-h4: italic !default;
$font-style-h5: italic !default;
$font-style-h6: normal !default;

$text-transform-h1: $headings-text-transform !default;
$text-transform-h2: $headings-text-transform !default;
$text-transform-h3: $headings-text-transform !default;
$text-transform-h4: $headings-text-transform !default;
$text-transform-h5: $headings-text-transform !default;
$text-transform-h6: none !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$line-height-base:        1.85714 !default; // 26/14

$lead-font-size:   16px !default;
$lead-font-weight: 400 !default;

// UL
$ul-bullet-color: $color-primary !default;
$ul-bullet-symbol: "\f00c" !default;
$ul-bullet-symbol-size: 14px !default;

// OL
$ol-number-color: #fff !default;

// Thumb
$thumb-border-radius: 4px !default;


//- Forms
//- ----------------------------------------------

$input-label-color:          #fff !default;
$input-label-text-transform: uppercase !default;
$input-bg:                   $color-dark !default;
$input-color:                $body-font-color !default;
$input-height-base:          46px !default;
$input-border:               $color-dark-lighten-2 !default;
$input-border-radius:        24px !default;
$input-font-size:            12px !default;

$input-bg-disabled:       $color-gray-3 !default;
$input-border-disabled:   $color-dark-lighten-2 !default;

$input-color-focus:       $body-font-color !default;
$input-bg-focus:          $input-bg !default;
$input-border-focus:      $color-2 !default;
$input-box-shadow-focus:  none !default;

$input-color-placeholder: rgba($color-gray-3, .6) !default;

$form-group-margin-bottom: 30px !default;

$input-height-base-sm:     33px !default;
$input-font-size-sm:       11px !default;

// Form Control background
$input-success-bg-pos:      center right 11px, 100% 0 !default;
$input-success-bg-size:     15px 12px !default;
$input-success-bg-img:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='#{$color-success}' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-warning-bg-pos:      center right .9em !default;
$input-warning-bg-size:     20px 16px !default;
$input-warning-bg-img:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$color-warning}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-danger-bg-pos:       center right 14px, 100% 0 !default;
$input-danger-bg-size:      11px 11px !default;
$input-danger-bg-img:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'%3E%3Cpath transform='translate(-190.844 -2353.84)' fill='#{$color-danger}' d='M190.843,2355.96l2.121-2.12,9.193,9.2-2.122,2.12Zm9.192-2.12,2.122,2.12-9.193,9.2-2.121-2.12Z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Checkbox
$checkbox-bg:               $color-dark !default;
$checkbox-border:           $input-border !default;
$checkbox-border-radius:    4px !default;
$checkbox-bg-active:        $checkbox-bg !default;
$checkbox-border-active:    $checkbox-border !default;
$checkbox-bg-disabled:      $input-bg-disabled !default;
$checkbox-border-disabled:  $input-border-disabled !default;
$checkbox-symbol:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$color-white}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
$checkbox-symbol-bg-size:   60% 60% !default;
$checkbox-symbol-disabled:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$color-gray}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Radio Button
$radio-bg:                  $color-dark !default;
$radio-border:              $input-border !default;
$radio-bg-disabled:         $input-bg-disabled !default;
$radio-border-disabled:     $input-border-disabled !default;
$radio-symbol:              str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$color-primary}'/%3E%3C/svg%3E"), "#", "%23");

// Select
$select-caret: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;


//- Tooltips
//- ----------------------------------------------

//** Tooltip text color
$tooltip-color:               #fff !default;
$tooltip-bg:                  $color-2 !default;
$tooltip-opacity:             1 !default;

$tooltip-arrow-width:         5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;


//- Accordion
//- ----------------------------------------------
$accordion-plus-bg:       $btn-default-bg !default;
$accordion-plus-bg-hover: $color-primary !default;


//- Form states and alerts
//- ----------------------------------------------

$state-success-text:             $color-success !default;
$state-success-bg:               $color-dark !default;
$state-success-border:           rgba(255,255,255,.05) !default;

$state-info-text:                $color-info !default;
$state-info-bg:                  $color-dark !default;
$state-info-border:              rgba(255,255,255,.05) !default;

$state-warning-text:             #ff7e1f !default;
$state-warning-bg:               $color-dark !default;
$state-warning-border:           rgba(255,255,255,.05) !default;

$state-danger-text:              $color-danger !default;
$state-danger-bg:                $color-dark !default;
$state-danger-border:            rgba(255,255,255,.05) !default;


//- Labels
//- ----------------------------------------------

$label-default-bg:            $color-gray !default;
$label-primary-bg:            $color-primary !default;
$label-success-bg:            $color-success !default;
$label-info-bg:               $color-info !default;
$label-warning-bg:            $color-warning !default;
$label-danger-bg:             $color-danger !default;

$label-color:                 #fff !default;
$label-link-hover-color:      #fff !default;
$label-border-radius:         .25em !default;


//- Modals
//- ----------------------------------------------

$modal-inner-padding:         15px !default;
$modal-title-padding:         15px !default;
$modal-title-line-height:     1.2em !default;
$modal-content-bg:                             #fff !default;
$modal-content-border-color:                   rgba(0,0,0,.2) !default;
$modal-content-fallback-border-color:          #999 !default;

$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity:      .9 !default;
$modal-header-border-color:   #e5e5e5 !default;
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    830px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;


//- Alerts
//- ----------------------------------------------

$alert-icon-width:            56px !default;

$alert-padding:               13px !default;
$alert-border-radius:         4px !default;
$alert-link-font-weight:      bold !default;
$alert-txt-accent-color:      $headings-color !default;
$alert-highlight-text-size:      11px !default;
$alert-highlight-text-transform: uppercase !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-icon-bg:       $color-dark-lighten !default;
$alert-success-icon-border:   $color-success !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-icon-bg:          $color-dark-lighten !default;
$alert-info-icon-border:      $color-info !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-icon-bg:       $color-dark-lighten !default;
$alert-warning-icon-border:   $color-warning !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-icon-bg:        $color-dark-lighten !default;
$alert-danger-icon-border:    $color-danger !default;
$alert-danger-border:         $state-danger-border !default;


//- Progress Bars
//- ----------------------------------------------

$progress-bg:                 $color-dark-lighten-2 !default;
$progress-bar-color:          #fff !default;
$progress-border-radius:      2px !default;
$progress-height:             4px !default;

$progress-bar-bg:             $color-primary !default;
$progress-bar-secondary-bg:   $color-4 !default;
$progress-bar-success-bg:     #aaf20e !default;
$progress-bar-warning-bg:     $color-warning !default;
$progress-bar-danger-bg:      $color-danger !default;
$progress-bar-info-bg:        $color-info !default;

$progress-label-color:        #fff !default;
$progress-label-style:        normal !default;


//- Circular Bar
//- ----------------------------------------------

$circular-bar-label-color:    $headings-color !default;


//- Card
//- ----------------------------------------------

$card-bg:                     $color-dark-lighten !default;
$card-header-bg:              $color-dark-2 !default;
$card-subheader-bg:           $color-dark-2 !default;
$card-header-border-width:    6px !default;
$card-header-font-size:       16px !default;
$card-header-text-transform:  uppercase !default;
$card-header-min-height:      72px !default;
$card-header-padding-top:     22px !default;
$card-border-color:           $color-dark-lighten-2 !default;
$card-border-radius:          4px !default;
$card-horizontal-padding:     24px !default;
$card-vertical-padding:       24px !default;
$card-horizontal-padding-lg:  43px !default;
$card-vertical-padding-lg:    43px !default;
$card-horizontal-padding-xlg: 70px !default;
$card-vertical-padding-xlg:   70px !default;
$card-bg-dotted:              url("../../assets/images/pattern-12x12.gif") !default;
$card-margin-vertical:        15px !default;



//- Jumbotron
//- ----------------------------------------------

$jumbotron-padding:                 2rem !default;
$jumbotron-bg:                      $card-bg !default;
$jumbotron-border-color:            $card-border-color !default;



//- Header
//- ----------------------------------------------

$header-bg:             $color-dark-2 !default;
$header-top-bg:         $color-dark !default;
$header-secondary-bg:   $color-dark-2 !default;
$header-primary-bg:     $color-dark-2 !default;
$header-primary-alt-bg: $color-dark !default; // used for Header Layout 4

// Top Bar
$top-bar-height:                    48px !default;
$top-bar-highlight:                 $color-primary !default;
$top-bar-link-color:                #fff !default;
$top-bar-text-color:                $color-gray-3 !default;
$top-bar-divider:                   true;
$top-bar-divider-color:             $top-bar-text-color !default;
$top-bar-arrow:                     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;
$top-bar-arrow-top:                 -2px !default;
$top-bar-arrow-inset-inline-start:  9px !default;
$top-bar-dropdown-inset-block-start: 40px !default;
$top-bar-dropdown-bg:               $color-dark-lighten !default;
$top-bar-dropdown-border:           $color-dark-lighten-2 !default;
$top-bar-dropdown-shadow:           0 0 12px rgba(0,0,0,.3) !default;
$top-bar-dropdown-link-color:       $color-white !default;
$top-bar-dropdown-link-color-hover: $color-primary !default;

$top-bar-social-icon-color:         $color-gray-3;
$top-bar-social-icon-color-hover:   $color-primary;

// Info Block
$header-info-block-heading-size:         9px !default;
$header-info-block-cart-sum-font-size:   11px !default;
$header-info-block-cart-sum-font-weight: 400 !default;
$header-info-block-cart-sum-font-style:  normal !default;
$header-info-block-color:                $color-primary !default;
$header-info-block-cart-sum-color:       $color-primary !default;
$header-info-block-link-color-mobile:    $color-gray-3 !default;

$header-info-block-color-mobile:          $color-primary !default;
$header-info-block-cart-sum-color-mobile: $color-primary !default;

// Search Form
$header-search-width-desktop:       200px !default;
$header-search-width-desktop-lg:    320px !default;
$header-search-icon:                #fff !default;
$header-search-input-bg:            $color-dark !default;
$header-search-input-border:        $header-search-input-bg !default;
$header-search-input-bg-focus:      $color-dark !default;
$header-search-input-border-focus:  $header-search-input-bg-focus !default;

$header-search-input-bg-mobile:            $color-dark !default;
$header-search-input-border-mobile:        $header-search-input-bg-mobile !default;
$header-search-input-bg-focus-mobile:      $color-dark-lighten !default;
$header-search-input-border-focus-mobile:  $header-search-input-bg-focus-mobile !default;

// Mobile Header
$header-mobile-bg: $color-dark-2 !default;
$header-mobile-height: 100px !default;
$header-mobile-logo-width: 92px !default;

// Nav
$nav-align:                left !default;
$nav-height:               70px !default;
$nav-font-family:          $font-family-accent !default;
$nav-text-transform:       uppercase !default;
$nav-font-weight:          700 !default;
$nav-font-style:           italic !default;
$nav-font-size:            14px !default;
$nav-font-color:           $color-white !default;
$nav-font-color-hover:     $color-primary !default;
$nav-active-border-height: 0 !default;
$nav-active-border-color:  transparent !default;
$nav-active-border-pos:    top !default;
$nav-active-bg:            transparent !default;
$nav-item-divider:         none !default;
$nav-item-padding:         0 30px !default;
$nav-arrow:                str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Submenu
$nav-sub-bg:               $color-dark-lighten !default;
$nav-sub-shadow:           none !default;
$nav-sub-font-family:      $font-family-accent !default;
$nav-sub-text-transform:   uppercase !default;
$nav-sub-font-weight:      700 !default;
$nav-sub-font-style:       italic !default;
$nav-sub-font-size:        12px !default;
$nav-sub-arrow:            str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;
$nav-sub-border-color:     $color-dark-lighten-2 !default;
$nav-sub-link-color:       $color-white !default;
$nav-sub-hover-bg-color:   $color-dark !default;
$nav-sub-hover-link-color: $color-primary !default;
$nav-sub-active-border-color: transparent !default;
$nav-sub-active-border-width: 0 !default;

// Megamenu
$nav-sub-megamenu-bg:                  $nav-sub-bg !default;
$nav-sub-megamenu-shadow:              none !default;
$nav-sub-megamenu-padding-vertical:   40px !default;
$nav-sub-megamenu-padding-horizontal: 70px !default;
$nav-sub-megamenu-link-color:       $color-gray !default;
$nav-sub-megamenu-link-color-hover: $color-primary !default;
$nav-sub-megamenu-link-font-size:   13px !default;
$nav-sub-megamenu-link-font-weight: 400 !default;
$nav-sub-megamenu-link-font-style:  normal !default;
$nav-sub-megamenu-link-text-transform: none !default;
$nav-sub-megamenu-title-color:      $color-white !default;
$nav-sub-megamenu-post-title-color: $color-white !default;
$nav-sub-megamenu-title-text-uppercase: uppercase !default;

// Mobile Nav
$nav-mobile-width:     270px !default;
$nav-mobile-bg:        $color-dark-lighten !default;
$nav-mobile-color:     #fff !default;
$nav-mobile-border:    $color-dark-lighten-2 !default;
$nav-mobile-font-size: 11px !default;
$nav-mobile-sub-bg:    $color-dark !default;
$nav-mobile-sub-color: $color-gray !default;

// Header Cart
$header-cart-width:                    380px !default;
$header-cart-bg:                       $color-dark !default;
$header-cart-shadow:                   0 20px 40px rgba(0,0,0,0.08) !default;
$header-cart-border:                   $color-dark-lighten-2 !default;
$header-cart-item-padding:             24px 24px 19px 24px !default;
$header-cart-item-border:              1px solid $header-cart-border !default;
$header-cart-product-cat-font-size:    8px !default;
$header-cart-product-cat-font-weight:  700 !default;
$header-cart-product-name-color:       $color-white !default;
$header-cart-product-name-color-hover: $color-primary !default;
$header-cart-product-name-font-style:  normal !default;
$header-cart-product-name-uppercase:   uppercase !default;
$header-cart-product-sum-size:         12px !default;
$header-cart-product-sum-color:        $color-white !default;
$header-cart-product-rating:           $color-primary !default;
$header-cart-product-rating-empty:     #4e4d73 !default;
$header-cart-product-rating-size:      10px !default;
$header-cart-product-price-color:      $header-cart-product-name-color !default;
$header-cart-subtotal-size:            11px !default;
$header-cart-subtotal-text-transform:  uppercase !default;
$header-cart-subtotal-label:           $color-white !default;
$header-cart-subtotal-sum:             $color-white !default;
$header-cart-product-close:            $color-gray-2 !default;
$header-cart-thumb-border-radius:      4px !default;
$header-cart-thumb-bg-color:           $color-dark-2 !default;

// Pushy Panel
$pushy-toggle-icon-color: #fff !default;
$pushy-panel-width: 380px !default;
$pushy-panel-bg: #fff !default;
$pushy-panel-bg-dark: $color-dark-lighten !default;


//- Page Heading
//- ----------------------------------------------
$page-heading-padding-sm: 50px 0 !default;
$page-heading-padding-lg: 45px 0 40px 0 !default;
$page-heading-bg-color: $color-dark !default;
$page-heading-bg-image-url: url("../images/esports/samples/page-heading.jpg") !default;
$page-heading-bg-pattern-url: url("../../assets/images/gridtile_4x4.gif") !default;
$page-heading-bg-pattern-size: 4px 4px !default;
$page-heading-bg-pattern-repeat: repeat !default;
$page-heading-bg-pattern-opacity: 1 !default;
$page-heading-font-size: 32px !default;
$page-heading-font-size-sm: 24px !default;
$page-heading-font-color: #fff !default;
$page-heading-font-highlight: $color-primary !default;
$page-heading-font-style: italic !default;
$page-heading-text-transform: uppercase !default;


//- Event Heading
//- ----------------------------------------------
$event-header-bg-color: $card-bg;


//- Player Page Heading
//- ----------------------------------------------
$player-page-heading-bg-color: $color-dark !default;
$player-page-heading-bg-image-url: url("../images/football/player-page-heading.jpg") !default;
$player-page-heading-bg-pattern-url: repeating-radial-gradient(at 50% 50%, #000, #000 1px, transparent 1px, transparent 4px) !default;
$player-page-heading-bg-pattern-opacity: 0.4 !default;
$player-page-heading-bg-pattern-size: 5px 5px !default;
$player-page-heading-bg-pattern-repeat: repeat !default;

$player-page-heading-second-name: $color-4 !default;
$player-page-heading-title: $body-font-color !default;


// Breadcrumbs
$breadcrumb-font-size:         10px !default;
$breadcrumb-text-transform:    uppercase !default;
$breadcrumb-font-weight:       bold !default;
$breadcrumb-divider-color:     $color-gray !default;
$breadcrumb-link-color:        $color-white;
$breadcrumb-link-hover-color:  $color-primary !default;
$breadcrumb-link-active-color: $color-gray !default;
$breadcrumb-item-padding:      5px !default;
$breadcrumb-divider:           quote("/") !default;


//- Filter
//- ----------------------------------------------
$filter-bg:                     $color-dark-2 !default;
$filter-border:                 $color-dark-lighten-2 !default;
$filter-active:                 $color-dark-lighten !default;
$filter-title-font-size:        12px !default;
$filter-title-text-transform:   uppercase !default;
$filter-label-color:            $body-font-color !default;
$filter-label-font-size:        9px !default;
$filter-label-font-weight:      bold !default;
$filter-label-text-transform:   uppercase !default;
$filter-label-letter-spacing:   -0.02em !default;
$filter-label-position-top:     20px !default;
$filter-label-position-top-sm:  20px !default;
$filter-color:                  #fff !default;
$filter-placeholder-font-style: italic !default;
$filter-padding-top:            25px !default;
$filter-padding-bottom:         12px !default;
$filter-padding-top-sm:         26px !default;
$filter-padding-bottom-sm:      12px !default;
$filter-padding-horizontal:     24px !default;
$filter-boxed-border-radius:    $card-border-radius !default;
$filter-caret:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;
$filter-dropdown-bg: $color-dark-lighten !default;
$filter-dropdown-border: $filter-border !default;
$filter-dropdown-color: $color-gray-3 !default;
$filter-dropdown-color-hover: #fff !default;
$filter-dropdown-font-size:       12px !default;
$filter-dropdown-font-style:      italic !default;


//- Content
//- ----------------------------------------------
$content-padding-vertical:    60px;
$content-padding-vertical-sm: 30px;


//- Widgets
//- ----------------------------------------------
$widget-title-text-transform: uppercase !default;


//- Blog
//- ----------------------------------------------
$post-category-1: $color-primary-darken !default;
$post-category-2: $color-2 !default;
$post-category-3: $color-3 !default;
$post-category-4: $color-4 !default;
$post-category-5: #f1533e !default;
$post-category-6: $color-2 !default;

$post-category-font-style: italic !default;

$post-title-color:            #fff !default;
$post-title-color-hover:      $color-4 !default;
$post-title-font-style:       italic !default;
$post-title-text-transform:   $headings-text-transform !default;
$post-title-font-size-sm:     18px !default;
$post-title-font-size:        24px !default;
$post-title-font-size-lg:     30px !default;
$post-title-line-height:      1em !default;
$post-excerpt-font-size:      $base-font-size !default;
$post-excerpt-line-height:    $base-line-height !default;
$post-excerpt-padding-top:    23px !default;
$post-excerpt-padding-top-sm: 13px !default;

$post-quote-bg:         $color-primary !default;
$post-quote-highlight:  #ffdc38 !default;
$post-quote-font-style: italic !default;
$post-quote-footer-font-style: italic !default;

$post-card-overlay-color: $color-dark !default;

$single-post-title-font-size-sm:   18px !default;
$single-post-title-font-size:      42px !default;
$single-post-title-line-height-sm: 1.0625em !default;
$single-post-title-line-height:    1em !default;
$single-post-title-margin-bottom:  0.55em !default;


//- Sidebar
//- ----------------------------------------------
$sidebar-content-font-size:   12px !default;
$sidebar-content-line-height: 20px !default;

// Widget Tabbed
$widget-tabbed-nav-bg:                transparent !default;
$widget-tabbed-nav-font-size:         11px !default;
$widget-tabbed-nav-font-style:        italic !default;
$widget-tabbed-nav-font-color:        rgba($headings-color, .4) !default;
$widget-tabbed-nav-font-color-hover:  $headings-color !default;
$widget-tabbed-nav-font-color-active: $headings-color !default;

// Widget Twitter
$widget-twitter-icon-color:              $color-gray-3 !default;
$widget-twitter-thumb-size:              40px !default;
$widget-twitter-thumb-border-radius:     2px !default;
$widget-twitter-name-font-size:          14px !default;
$widget-twitter-name-font-size-sm:       12px !default;
$widget-twitter-name-font-weight:        700 !default;
$widget-twitter-name-font-style:         italic !default;
$widget-twitter-name-text-transform:     uppercase !default;
$widget-twitter-username-font-size:      10px !default;
$widget-twitter-username-font-weight:    700 !default;
$widget-twitter-username-font-style:     italic !default;
$widget-twitter-username-text-transform: uppercase !default;
$widget-twitter-username-color:          $body-font-color !default;
$widget-twitter-username-color-hover:    $link-color-hover !default;
$widget-twitter-txt-font-size:           12px !default;
$widget-twitter-txt-line-height:         20px !default;
$widget-twitter-txt-color:               #2bd0ff !default;
$widget-twitter-txt-color-hover:         darken(#2bd0ff, 15%) !default;
$widget-twitter-timestamp-font-size:     11px !default;
$widget-twitter-timestamp-color:         $body-font-color !default;
$widget-twitter-timestamp-color-hover:   $link-color-hover !default;

// Widget Results
$widget-results-title-font-size:         10px !default;
$widget-results-title-text-transform:    uppercase !default;
$widget-results-title-vertical-padding:  12px !default;

// Widget Featured Player
$widget-player-stats-value-size:          24px !default;
$widget-player-stats-value-color:         $headings-color !default;
$widget-player-stats-value-margin:        .5em !default;
$widget-player-stats-label-text-transform: none !default;


//- Single Post
//- ----------------------------------------------
$single-post-author-avatar-size:          100px !default;
$single-post-author-avatar-border-radius: 4px !default;

$single-post-3-image:                     url("../images/esports/samples/single-post-img14.jpg") !default;


//- Pagination
//- ----------------------------------------------
$pagination-margin:           30px 0 20px 0 !default;
$pagination-bg:               $color-dark-2 !default;
$pagination-color:            #fff !default;
$pagination-font-size:        12px !default;
$pagination-border:           $card-border-color !default;
$pagination-bg-hover:         $color-primary-darken !default;
$pagination-color-hover:      #fff !default;
$pagination-border-hover:     $color-primary-darken !default;
$pagination-bg-active:        $color-primary-darken !default;
$pagination-color-active:     #fff !default;
$pagination-border-active:    $color-primary-darken !default;


//- Content Filter
//- ----------------------------------------------
$content-filter-bg:                   $color-dark-lighten !default;
$content-filter-border:               $filter-border !default;
$content-filter-padding-top:          19px !default;
$content-filter-padding-bottom:       19px !default;
$content-filter-padding-left:         23px !default;
$content-filter-padding-right:        23px !default;
$content-filter-padding-top-sm:       18px !default;
$content-filter-padding-bottom-sm:    18px !default;
$content-filter-color:                $color-gray-3 !default;
$content-filter-color-hover:          #fff !default;
$content-filter-bg-active:            $color-dark-2 !default;
$content-filter-active-highlight:     $color-2 !default;
$content-filter-label-font-size:      12px !default;
$content-filter-label-font-style:     italic !default;
$content-filter-label-font-weight:    700 !default;
$content-filter-sublabel-font-size:   9px !default;
$content-filter-sublabel-font-style:  italic !default;
$content-filter-sublabel-font-weight: 700 !default;


//- Team
//- ----------------------------------------------
$team-select-color: #fff !default;
$team-select-arrow: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='#{$color-white}' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E"), "#", "%23") !default;


//- Shop
//- ----------------------------------------------
$shop-price-filter-bar-color: $color-primary !default;
$shop-price-filter-handle-color: $color-primary-darken !default;

// Account
$account-nav-bg: transparent !default;
$account-nav-bg-hover: rgba(0,0,0,.03) !default;
$account-nav-color: $headings-color !default;
$account-nav-color-hover: $account-nav-color !default;
$account-nav-color-active: $headings-color !default;
$account-nav-border-width: 6px !default;
$account-nav-font-size: 12px !default;
$account-nav-subtitle-color: $body-font-color !default;
$account-nav-subtitle-font-weight: 400 !default;

$product-color-1: linear-gradient(to left top, #fe2b00, #f7d500) !default;
$product-color-2: linear-gradient(to left top, #003e78, #33fff3) !default;
$product-color-3: linear-gradient(to left top, #00adbd, #7cffd0) !default;
$product-color-4: linear-gradient(to left top, #3f1464, #dd2f8d) !default;
$product-color-5: linear-gradient(to left top, #140078, #4dcbff) !default;
$product-color-6: linear-gradient(to left top, #006f3f, #bdff3d) !default;

// Rating
$product-rating-color:          $color-primary !default;
$product-rating-color-disabled: $color-gray-3 !default;

// Cart
$product-base-font-size:      13px !default;
$product-name-font-size:      12px !default;
$product-name-font-size-lg:   13px !default;
$product-name-text-transform: uppercase !default;
$product-cat-font-size:       8px !default;
$product-cat-color:           $body-font-color !default;

// Filter
$shop-filter-height:              72px !default;
$shop-layout-filter-color:        #4e4d73 !default;
$shop-layout-filter-color-active: $color-4 !default;

// Sale
$product-sale-bg: $color-primary !default;
$product-sale-color: #fff !default;


//- Tournament
//- ----------------------------------------------
$tournament-team-bg-winner: $color-dark-2;
$tournament-divider: $color-dark-2;
$tournament-winner-icon: $color-white;


//- Footer
//- ----------------------------------------------
$footer-widgets-bg:        url("../images/esports/samples/footer-bg.jpg") #3b2f4c !default;
$footer-secondary-bg:      $color-dark !default;
$footer-secondary-side-bg: $color-primary !default;
$footer-font-size:         13px !default;
$footer-line-height:       22px !default;
$footer-font-color:        $body-font-color !default;

// Footer Widgets
$footer-widget-title:                #fff !default;
$footer-widget-title-font-size:      14px !default;
$footer-widget-title-font-style:     normal !default;
$footer-widget-title-margin:         2.4em !default;

$footer-widget-post-title-font-style: italic !default;
$footer-widget-post-title-line-height: 1.33em !default;
$footer-widget-post-thumb-border-radius: 4px !default;

$footer-widgets-padding-top:          30px !default;
$footer-widgets-padding-bottom:       30px !default;
$footer-widgets-padding-top-md:       60px !default;
$footer-widgets-padding-bottom-md:    70px !default;
$footer-widgets-padding-top-lg:       80px !default;
$footer-widgets-padding-bottom-lg:    96px !default;

// Footer Info Section
$footer-info-bg:        $color-dark-2 !default;

// Contact Form
$footer-widget-contact-form-input-bg:     #232335 !default;
$footer-widget-contact-form-input-border: #232335 !default;

// Sponsors
$sponsors-title-color: $headings-color;

// Footer Copyright
$footer-copyright-font-size:    10px !default;
$footer-copyright-border-size:  1px !default;
$footer-copyright-border-style: solid !default;
$footer-copyright-border-color: $color-2 !default;

$footer-copyright-padding:           24px !default;
$footer-copyright-padding-lg:        50px !default;
$footer-copyright-link-color:        #fff !default;
$footer-copyright-link-color-active: $color-4 !default;

// Footer Nav
$footer-nav-font-size:    12px !default;
$footer-nav-font-size-sm: 10px !default;
$footer-nav-color:        #fff !default;
$footer-nav-color-active: $color-4 !default;


//- SVG Icon
//- ----------------------------------------------
$svg-stroke: $color-primary !default;
$footer-contact-info-icon-color: $color-4 !default;


//- Info Block
//- ----------------------------------------------
$info-block-icon-color:  $svg-stroke !default;
$info-block-link-color:  $color-gray-3 !default;
$info-block-font-size:   9px !default;
$info-block-font-style:  normal !default;
$info-block-font-weight: 400 !default;


//- Icobox
//- ----------------------------------------------
$icobox-icon-border-color:    #595772 !default;
$icobox-icon-color-primary:   $color-primary-darken !default;
$icobox-icon-color-secondary: $color-4 !default;
$icobox-title-font-size:      22px !default;
$icobox-title-font-style:     italic !default;
$icobox-title-text-transform: uppercase !default;


//- Timeline
//- ----------------------------------------------
$timeline-marker-color: $color-4 !default;
$timeline-marker-line-color: #4e4d73 !default;


//- noUI Range Slider
//- ----------------------------------------------
$range-bg-color: $color-dark-lighten-2 !default;
$range-border-radius: 2px !default;
$range-handle-border-radius: 50% !default;
$range-top-padding: 18px !default;
$range-label-color: $headings-color !default;
$range-label-price-color: $headings-color !default;


//- Widget
//- ----------------------------------------------
$widget-decor-color-1: rgba($color-2, .4) !default;
$widget-decor-color-2: rgba($color-2, .7) !default;
$widget-decor-color-3: rgba($color-2, .9) !default;


//- Slick Carousel
//- ----------------------------------------------
$slick-font-family: "Font Awesome 5 Free" !default;
$slick-loader-path: "./" !default;
$slick-arrow-bg-color: $color-gray-2;
$slick-arrow-color: white !default;
$slick-arrow-bg-color-hover: $color-primary-darken;
$slick-dot-color: #d7d8da !default;
$slick-dot-color-on-hover: $color-2 !default;
$slick-dot-color-active: $color-primary !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


//- Preloader
//- ----------------------------------------------
$preloader-bg: $color-dark !default;
$preloader-percent-color: #fff !default;
$preloader-percent-accent-color: $color-primary !default;
$preloader-loading-txt: "Loading..." !default;
$preloader-title: "Alchemists Template" !default;

// For generating grid via class
$grid-gutter-width: 16px !default;
