/* Event: Event Result Box */
.alc-event-result-box {

}

	// Header
	.alc-event-result-box__header {
		display: flex;
		align-items: center;
	}
		.alc-event-result-box__header-heading {

		}
			.alc-event-result-box__header-title {
				font-size: 14px;
				font-style: normal;
				margin-bottom: .25em;
			}
			.alc-event-result-box__header-subtitle {
				display: block;
				font-size: 10px;
				line-height: 1.2em;
			}

		.alc-event-result-box__header-date,
		.alc-event-result-box__header-venue {
			font-family: $font-family-accent;
			font-size: 10px;
			font-weight: 700;
			line-height: 1.2em;
			text-transform: uppercase;
		}

	// Header: Centered
	.alc-event-result-box__header--center {
		width: 100%;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		.alc-event-result-box__header-heading {
			flex: 1;
			text-align: center;

			@media (max-width: 767px) {
				width: 100%;
				margin: 10px 0;
				flex-basis: 100%;
			}
		}

		.alc-event-result-box__header-date,
		.alc-event-result-box__header-venue {
			flex: 1;

			@media (max-width: 767px) {
				width: 100%;
				text-align: center;
				flex-basis: 100%;
			}
		}

		.alc-event-result-box__header-venue {

			@media (min-width: 768px) {
				text-align: right;
			}
		}
	}

	// Header: Side
	.alc-event-result-box__header--side {

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		.alc-event-result-box__header-heading {
			order: 0;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.alc-event-result-box__header-date {
			order: 1;
			margin-left: auto;
		}

		.alc-event-result-box__header-venue {
			order: 2;
			margin-left: 20px;
		}

		.alc-event-result-box__header-date,
		.alc-event-result-box__header-venue {

			@media (max-width: 767px) {
				width: 100%;
				margin-left: 0;
				margin-top: 10px;
			}
		}

	}


	// Team & Results
	.alc-event-result-box__teams {

	}

		// Team Body
		.alc-event-result-box__team-body {
			display: flex;
			align-items: center;
			flex: 1;

			@media (max-width: 575px) {
				flex-wrap: wrap;
				align-items: center;
			}
		}

			// Team Meta
			.alc-event-result-box__team-meta {
				flex: 1;

				@media (max-width: 575px) {
					width: 100%;
					text-align: center;
				}
			}

				// Team Logo
				.alc-event-result-box__team-logo {
					margin-right: 20px;

					@media (max-width: 575px) {
						display: flex;
						align-items: center;
						width: 100%;
						min-height: 70px;
						margin-bottom: 15px;
						text-align: center;
						justify-content: center;
						margin-right: 0;
					}

					@media (min-width: 992px) {
						margin-right: 34px;
					}
				}

		// Team Name
		.alc-event-result-box__team-name {
			font-size: 14px;
			line-height: 1.2em;
			margin-bottom: .1em;
			font-style: normal;

			@media (max-width: 575px) {
				font-size: 12px;
			}
		}

		// Team Subtitle
		.alc-event-result-box__team-subtitle {
			display: block;
			font-size: 10px;
			line-height: 1.2em;
		}


	// Style: Default
	.alc-event-result-box__teams--default {
		display: flex;
		width: 100%;
		height: 146px;

		@media (max-width: 575px) {
			align-items: center;
		}

		// Team
		.alc-event-result-box__team {
			display: flex;
			width: 43%;
		}

			// Team Image
			.alc-event-result-box__team-img {
				width: 260px;
				position: relative;
				overflow: hidden;

				&::before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					background-size: cover;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					width: 0;
					height: 0;
					font-size: 0;
					line-height: 0;
					border-top: 146px solid $card-bg;
					z-index: 3;
				}

				@media (max-width: 991px) {
					display: none;
				}

				@media (min-width: 992px) {
					width: 180px;
				}

				@media (min-width: 1200px) {
					width: 260px;
				}

				img {
					display: block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.alc-event-result-box__team--first .alc-event-result-box__team-img {

				&::before {
					background-image: url("../images/esports/samples/event-team1-bg.jpg");
				}

				&::after {
					right: 0;
					border-left: 60px solid transparent;
				}
			}
			.alc-event-result-box__team--second .alc-event-result-box__team-img {
				order: 3;

				&::before {
					background-image: url("../images/esports/samples/event-team2-bg.jpg");
				}

				&::after {
					left: 0;
					border-right: 60px solid transparent;
				}
			}

			// Team Body
			.alc-event-result-box__team-body {

			}

				// Team Meta
				.alc-event-result-box__team-meta {

				}

				.alc-event-result-box__team--first .alc-event-result-box__team-meta {
					text-align: right;

					@media (max-width: 575px) {
						text-align: center;
					}
				}

					// Team Logo
					.alc-event-result-box__team-logo {

					}

					// Team Logo: 1st Team
					.alc-event-result-box__team--first .alc-event-result-box__team-logo {
						order: 2;
						margin-left: 20px;
						margin-right: 0;

						@media (max-width: 575px) {
							order: 0;
							margin-left: 0;
							margin-right: 0;
						}

						@media (min-width: 992px) {
							margin-left: 34px;
							margin-right: 0;
						}
					}

					// Team Logo: 2nd Team
					.alc-event-result-box__team--second .alc-event-result-box__team-logo {
						margin-right: 20px;

						@media (max-width: 575px) {
							margin-right: 0;
						}

						@media (min-width: 992px) {
							margin-right: 34px;
						}
					}


		// Score
		.alc-event-result-box__team-score {
			width: 14%;
			text-align: center;
			align-self: center;
			font-size: 24px;
			line-height: 1.2em;
			font-family: $font-family-accent;
			font-style: italic;
			font-weight: 700;
			color: $headings-color;
			letter-spacing: -0.02em;

			@media (min-width: 992px) {
				font-size: 42px;
			}

			.loss {
				color: $color-gray-2;
			}
		}
	}


	// Style: Board
	.alc-event-result-box__teams--board {
		display: flex;
		border-style: solid;
		border-color: $card-border-color;
		border-width: 1px 0 0 1px;
		margin: -1px;

		.alc-event-result-box__teams-wrapper {
			display: flex;
			flex-direction: column;
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			border-style: solid;
			border-color: $card-border-color;
			border-width: 0 1px 1px 0;
		}

		// Teams
		.alc-event-result-box__team {
			display: flex;
			border-style: solid;
			border-color: $card-border-color;
			border-width: 0;
			align-items: center;
			height: 79px;
			padding-left: calc($card-horizontal-padding / 3);
			padding-right: calc($card-horizontal-padding / 3);

			@media (min-width: 768px) {
				height: 99px;
				padding-left: $card-horizontal-padding;
				padding-right: $card-horizontal-padding;
			}

			@media (min-width: 992px) {
				height: 123px;
			}
		}
		.alc-event-result-box__team + .alc-event-result-box__team {
			border-width: 1px 0 0 0;
		}

			.alc-event-result-box__team-logo {
				margin-bottom: 5px;
				min-height: auto;
				margin-right: 5px;

				img {
					max-width: 32px;
					height: auto;
				}

				@media (min-width: 576px) and (max-width: 767px) {
					width: 50px;
					margin-bottom: 0;
					margin-right: 0;
				}

				@media (min-width: 768px) {
					width: 65px;
					margin-bottom: 0;
					margin-right: 0;

					img {
						max-width: 44px;
						height: auto;
					}
				}

				@media (min-width: 992px) {
					width: 96px;
					margin-right: 0;

					img {
						max-width: none;
					}
				}
			}

			.alc-event-result-box__team-name {

				@media (max-width: 575px) {
					font-size: 11px;
				}
			}

			.alc-event-result-box__team-subtitle {

				@media (max-width: 575px) {
					display: none;
				}
			}

		// Score
		.alc-event-result-box__team-score {
			display: flex;
			flex-direction: column;
		}
			.alc-event-result-box__team-score-row {
				display: flex;

			}
				.alc-event-result-box__team-score-cell {
					display: flex;
					align-items: center;
					justify-content: center;
					border-style: solid;
					border-color: $card-border-color;
					border-width: 0 1px 1px 0;
					text-align: center;
					color: rgba(255,255,255,.15);
					font-family: $font-family-accent;
					font-weight: 400;
					line-height: 1.2em;
					background-color: rgba(0,0,0,.1);
					width: 44px;
					height: 80px;
					font-size: 24px;

					@media (max-width: 575px) {

					}

					@media (min-width: 768px) {
						width: 80px;
						height: 100px;
						font-size: 32px;
					}

					@media (min-width: 992px) {
						width: 100px;
						height: 124px;
						font-size: 38px;
					}

					@media (min-width: 1200px) {
						width: 120px;
						font-size: 42px;
					}

					&.cell-highlight {
						color: $color-gray-2;
					}

					&.cell-result {
						color: $color-white;

						@media (min-width: 992px) {
							width: 120px;
						}

						@media (min-width: px) {
							width: 140px;
						}
					}
				}
	}


	// Section
	.alc-event-result-box__section {

	}
		.alc-event-result-box__section-inner {
			padding: calc($card-vertical-padding / 2) calc($card-horizontal-padding / 2);

			@media (min-width: 992px) {
				padding: $card-vertical-padding $card-horizontal-padding;
			}
		}

	/* Game Stats (additional) */
	.alc-event-result-box__stats-circular-bars {
		display: flex;
		align-items: center;
		margin: 0;
		flex-wrap: wrap;

		.alc-event-result-box__stats-circular-item {
			font-size: 12px;
			line-height: 1.2em;
			flex-grow: 1;
			flex-basis: 0;
			max-width: 100%;
			display: flex;
			align-items: center;

			@media (max-width: 575px) {
				flex-basis: 33.3%;
				margin-bottom: 10px;
			}

			@media (max-width: 991px) {
				flex-direction: column;
			}
		}
			.alc-event-result-box__stats-circular-bar {
				margin-right: 10px;

				.circular {
					margin-top: 0;
				}

				@media (max-width: 991px) {
					margin: 0;
				}
			}
			.alc-event-result-box__stats-meta {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%;

				@media (max-width: 991px) {
					text-align: center;
				}
			}
				.alc-event-result-box__stats-value {
					display: block;
					color: $headings-color;
					font-family: $font-family-accent;
					font-weight: 700;
				}
				.alc-event-result-box__stats-label {
					font-size: 9px;
					line-height: 1.2em;
					display: block;
				}

		.alc-event-result-box__stats-circular-item--reverse {

			@media (min-width: 992px) {

				.alc-event-result-box__stats-circular-bar {
					margin-left: 10px;
					margin-right: 0;
					order: 1;
				}

				.alc-event-result-box__stats-meta {
					order: 0;
					text-align: right;
				}
			}
		}

		.alc-event-result-box__stats-circular-item--center {
			text-align: center;
		}

	}


	// Footer

	// Tabs
	.alc-event-result-box__tabs {
		display: flex;
		margin: 0;
		border-top: 1px solid $card-border-color;
	}
		.alc-event-result-box__tab {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}
			.alc-event-result-box__tab-link {
				display: flex;
				height: 100%;
				flex-direction: column;
				padding: 18px 24px;
				font-family: $font-family-accent;
				font-size: 12px;
				line-height: 1.2em;
				text-transform: uppercase;
				font-style: italic;
				color: $color-gray-3;
				font-weight: 700;
				transition: background-color .2s ease;

				@media (max-width: 767px) {
					padding-left: 16px;
					padding-right: 10px;
				}

				.alc-event-result-box__tab + .alc-event-result-box__tab & {
					border-left: 1px solid $card-border-color;
				}

				// hover & active states
				&:hover,
				.active & {
					background-color: $color-dark-2;

					.alc-event-result-box__tab-title {
						color: $color-white;
					}

					.alc-event-result-box__tab-subtitle {
						color: $body-font-color;
					}
				}

				// active state
				.active & {
					border-left: 6px solid $color-2 !important;
					padding-left: 18px;

					@media (max-width: 767px) {
						padding-left: 10px;
					}
				}
			}

			.alc-event-result-box__tab:first-child .alc-event-result-box__tab-link {
				border-bottom-left-radius: $card-border-radius - 1;
			}

				.alc-event-result-box__tab-title {
					transition: color .2s ease;
				}
				.alc-event-result-box__tab-subtitle {
					font-size: 9px;
					line-height: 1.35em;
					transition: color .2s ease;
				}
